import React from "react";
import {
  faAddressCard,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faLocationDot,
  faMoneyCheckDollar,
  faShip,
  faUsers,
  faCheckToSlot
} from "@fortawesome/free-solid-svg-icons";

const AdminDashboard = React.lazy(() =>
  import("../pages/adminModule/adminDashboard")
);
const CreatePorts = React.lazy(() =>
  import("../pages/adminModule/createPorts")
);
const CreatePortConservator = React.lazy(() =>
  import("../pages/adminModule/CreatePortConservator")
);
const District = React.lazy(() => import("../pages/portConservator/district"));
const SpotBookingApproval = React.lazy(() =>
  import("../pages/portConservator/spotBookingApproval")
);

const SandRate = React.lazy(() => import("../pages/adminModule/sandRate"));
const AddTonLimitAdmin = React.lazy(() =>
  import("../pages/adminModule/tonLimit")
);
const Bank = React.lazy(() => import("../pages/portConservator/bank"));

export const adminRoutes = [
  {
    name: "DashBoard",
    component: <AdminDashboard />,
    role: "admin",
    path: "/adminDashboard",
    // mainMenu: true,
    icon: faAddressCard,
  },
  {
    name: "Port Conservators ",
    component: <CreatePortConservator />,
    role: "admin",
    path: "/createportconservators",
    mainMenu: true,
    icon: faUsers,
  },
  {
    name: "Ports ",
    component: <CreatePorts />,
    role: "admin",
    path: "/createports",
    mainMenu: true,
    icon: faShip,
  },
  {
    name: "Add District",
    component: <District />,
    role: "admin",
    mainMenu: true,
    path: "/",
    icon: faLocationDot,
  },
  {
    name: "Spot Booking",
    component: <SpotBookingApproval  />,
    role: "admin",
    path: "/spotBookingApproval",
    mainMenu: true,
    icon: faCheckToSlot,
  },
  {
    name: "Sand Rate",
    component: <SandRate />,
    role: "admin",
    mainMenu: true,
    path: "/sandRate",
    icon: faMoneyCheckDollar,
  },
  {
    name: "Ton Limit",
    component: <AddTonLimitAdmin />,
    role: "admin",
    mainMenu: true,
    path: "/addTonLimitAdmin",
    icon: faArrowDownWideShort,
  },
  {
    name: "Add Bank",
    component: <Bank />,
    role: "admin",
    mainMenu: true,
     path: "/bank",
     icon: faMoneyCheckDollar,
  },
];

